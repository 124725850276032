.profile-table {
  text-align: left;

  &__row {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    padding: 0.7rem 0;
  }

  &__row + &__row {
    border-top: 1px solid #eee;
  }
  
  &__label {
    margin-right: auto;
  }

  &__value {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__label,
  &__value {
    flex: 1;
  }

  @include respond(xs) {
    &__row {
      flex-direction: column;
      align-items: flex-start;
    }

    &__label,
    &__value {
      flex: 0;
      width: 100%;
      text-align: left;
    }

    &__label {
      font-size: 0.75rem;
      margin-right: 0;
      line-height: 1rem;
    }
  }
}
