@import '_variables';

// Default styling for all dialogs
mat-dialog-container.mat-mdc-dialog-container {
  padding: 0;
  height: auto;
  overflow: unset;
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @include respond(sm) {
    border-radius: 0.5rem;
  }

  > * {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  .mat-mdc-dialog-actions {
    margin: 0;
    border-top: 1px solid $color-grey-5;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;

    padding: 1.5rem;
  }

  .mat-mdc-dialog-actions button {
    width: 100%;

    + button {
      margin-left: 0.5rem;
    }
  }

  .mat-mdc-dialog-title {
    min-height: 4.25rem;
    padding: 0.875rem 0.5rem 0.875rem 1.5rem;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    text-align: left;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-5;

    h3 {
      font-weight: 500;
      flex: 1;
    }

    .btn {
      height: 2.5rem;
    }
  }

  .mat-mdc-dialog-content {
    flex: 1 1 auto;
    color: $color-font-1;

    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 86vh !important;
    margin: 0;
    padding: 1.5rem;

    color: $color-grey-2;

    p {
      margin: 0;
      padding: 0;
      color: $color-font-1;
    }
  }
}

// Styles the overlay generated by mat-dialog
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

par-root par-layout {
  transition: 0.15s filter linear;
  display: block;
}

@keyframes slideup {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.cdk-global-overlay-wrapper {
  @include respond(sm) {
    align-items: center;
  }
  @include respond(xs) {
    animation: slideup 0.6s;
    align-items: flex-end !important;
  }
}
.mat-mdc-snack-bar-container {
  align-self: flex-end;
}

.cdk-overlay-pane {
  display: flex;
  justify-content: center;

  max-width: 100vw !important;

  @include respond(sm) {
    max-width: 90vw !important;

    &.small {
      width: 26rem;
    }
    &.medium {
      width: 39rem;
    }
    &.large {
      width: 52rem;
    }
  }
}
