html {
  background: $color-grey-8;
}

.ft-formfield.is-invalid::after, .ft-formfield.is-valid::after {
  display: none;
}

.is-hidden-on-mobile {
  @include respond(xs) {
    display: none;
  }
}

.is-shown-on-mobile {
  @include respond(sm) {
    display: none;
  }
}

/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */

.section-title {
  text-align: center;
}

.container, .card-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card-sub-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-row-content {
  display: flex;
  gap: 1rem;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 92vw;
  padding-bottom: 1rem;
  margin-top: 1.5rem;

  @include respond(md) {
    max-width: 45em; // 720px
  }

  @include respond(lg) {
    max-width: 45em; // 720px
  }

  // @include respond(xl) {
  //   max-width: 71.25em; // 1140px
  // }

  // @include respond(lg) {
  //   max-width: 60em; // 960px;
  // }

  // @include respond(xl) {
  //   max-width: 71.25em; // 1140px
  // }

  // @include respond(xxl) {
  //   max-width: 82.5em; // 1320px
  // }
}
