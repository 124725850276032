.card {

  &--flex {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
  }

  @include respond(sm) {
    padding: 2rem;
  }

  &__title {
    text-align: center;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    button + button {
      margin-left: .5rem;

      @include respond(sm) {
        margin-bottom: 1rem;
      }
    }
  }
}
