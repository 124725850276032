@import './components/dialog';
@import './components/drag-drop';
@import './components/spinner';

.mat-input-element {
  caret-color: black;
}

mat-divider.mat-divider {
  height: 1px;
  width: 100%;
  background-color: $color-grey-3;
  border: none;
}
